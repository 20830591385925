import React from 'react';
import styles from './App.module.scss';
import { encodeToMorse } from './morseEncoder';

const MorseCharacter: React.FC<{ symbol: string }> = ({ symbol }) => {
  if (symbol === '.') {
    return <span className={`${styles.morseCharacter} ${styles.dot}`}>•</span>;
  } else if (symbol === '-') {
    return <span className={`${styles.morseCharacter} ${styles.dash}`}>−</span>;
  }
  return <span className={styles.morseCharacter}>&nbsp;</span>;
};

const MorseMessage: React.FC<{ message: string }> = ({ message }) => {
  const morseCode = encodeToMorse(message);
  return (
    <div className={styles['morse-ticker']}>
      {morseCode.split('').map((symbol, index) => (
        <MorseCharacter key={index} symbol={symbol} />
      ))}
    </div>
  );
};

const App: React.FC = () => {
  return (
    <div className={styles['morse-container']}>
      <MorseMessage message="5 9 2 5 1 7" />
    </div>
  );
};

export default App;
